.input{
  margin: .8rem 0;
  
  &__text,
  &__select{
    width: 100%;
    padding: 1rem 2rem;
    font-size: 1.6rem;
    line-height: 2rem;
    border-radius: 2px;
    border: none;
    display: block;

    transition: all .2s linear;
    border-bottom: 2px solid transparent;

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &____option {
      color: $color-text-placeholder;
      font-family: $font-text;
      text-transform: capitalize;
      font-size: 1.3rem;
    }


    &:focus {
      outline: none;
      border-bottom: 2px solid $color-nora-primary-light-2;
    }


  }

  &__select,
  &____option {
    color: $color-text-placeholder;
    font-family: $font-text;
    text-transform: capitalize;
    font-size: 1.5rem;
    background-color: #fff;
    line-height: 2.2rem;
  }

  &__invalid{
    border-bottom: 2px solid $color-alarm;
  }

  &__label {
    display: block;
    font-size: 1.3rem;
    margin-left: 2rem;
    margin-top: 1rem;
    font-weight: 600;
    text-transform: capitalize;
    color: $color-text-light-1;
    transition: all .5s;
  }

  &__text:placeholder-shown + &__label{
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
  }
}