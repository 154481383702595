.pop-up{
  // background-color: aqua;
  backdrop-filter: blur(0px);
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  justify-items: center;
  align-items: center;
  transform: translateY(-200%);
  overflow: scroll;
  transition: all .5s linear;
  // visibility: hidden;
  opacity: 0;
  &__enter{
    opacity: 1;
    transform: translateY(0);
    backdrop-filter: blur(10px);
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  z-index: 10;
  display: grid;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);

}