.painting-slider {
  width: 100vw;
  height: 100vh;
  position: relative;
  &__modify {
    position: absolute;
    padding: 0.5rem 1rem;
    z-index: 500;
    top: 1rem;
    left: 1rem;
    color: $color-milk-white;
    display: flex;

    & > * {
      padding: 0.5rem 1rem;
      border: 1px solid $color-milk-white;
      cursor: pointer;
      text-transform: uppercase;
      backdrop-filter: blur(5px);
      background-color: rgba(0, 0, 0, 0.695);
      border-radius: 2px;
      transition: all 0.2s linear;
      box-shadow: $shadow-text-dark;

      &:hover {
        color: black;
        background-color: $color-milk-white;
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  &__exit {
    height: 3rem;
    width: 3rem;
    position: absolute;
    right: 2rem;
    top: 1.5rem;
    border: 1px solid white;
    z-index: 500;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: $shadow-text-dark;
    background-color: black;
    transition: all 0.2s linear;

    &:hover {
      transform: scale(1.05);
      background-color: $color-milk-white;
      color: black;
      &::before,
      &::after {
        background-color: black;
      }
    }

    &:active {
      transform: scale(1);
    }
    &::before,
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      top: calc(50% - 0.5px);
      background-color: #fff;
      transform-origin: center;
    }
    &::before {
      transform: rotateZ(45deg);
    }
    &::after {
      transform: rotateZ(-45deg);
    }
  }

  &__container {
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100vw;
    height: 100vh;

    @media only screen and (max-width: $bp-xxxl) {
      grid-template-columns: 1fr max-content;
      max-height: 100vh;
      justify-items: center;
    }
    @media only screen and (max-width: $bp-md) and (orientation: portrait) {
      grid-template-columns: 1fr;
      grid-template-rows: 80% 20%;
      height: 95vh;
    }
    @media only screen and (max-width: $bp-sm) and (orientation: portrait) {
      align-self: start;
      // margin-top: 0rem;
      height: 80vh;
      overflow: hidden;
    }
  }

  &__img {
    height: inherit;
    width: 100%;
    object-fit: contain;
    position: relative;
    img {
      height: 100%;
      position: relative;
    }

    @media only screen and (max-width: $bp-md) and (orientation: portrait) {
      height: 100%;
      width: 100%;
    }
  }
  &__info {
    padding: 0 2rem;
    display: grid;
    grid-template-columns: 1fr;
    min-height: 40rem;
    max-height: 100vh;
    & > * {
      display: block;
    }
    @media only screen and (max-width: $bp-xxxl) {
      align-self: center;
    }
    @media only screen and (max-width: $bp-lg) and (orientation: landscape) {
      min-height: 30rem;
    }
    @media only screen and (max-width: $bp-md) and (orientation: portrait) {
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
      width: 100%;
      min-height: 10vh;
    }
    @media only screen and (max-width: $bp-sm) and (orientation: portrait) {
      padding-top: 2rem;
      max-height: 10vh;
    }
  }
  &__title {
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    @media only screen and (orientation: landscape) and (max-width: $bp-lg) {
      font-size: 1.4rem;
    }
    @media only screen and (max-width: $bp-sm) {
      font-size: 1.4rem;
    }
  }
  &__content {
    max-width: 15rem;
    font-weight: 300;
    border-bottom: 1px solid white;
    font-size: 1.4rem;
    text-transform: capitalize;
    display: block;

    @media only screen and (max-width: $bp-md) and (orientation: portrait) {
      width: 100%;
    }
    @media only screen and (max-width: $bp-sm) and (orientation: portrait) {
      font-size: 1.2rem;
    }
  }
  &__name {
    @media only screen and (orientation: portrait) {
      grid-column: 1 / 3;
    }
  }

  &__button {
    font-size: 4rem;
    z-index: 10;
    position: fixed;
    top: 50%;
    background-color: transparent;
    color: white;
    cursor: pointer;
    border: none;

    &-left {
      left: 0.5rem;
    }
    &-right {
      right: 0.5rem;
    }
  }
}

.update-bg {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.912);
  z-index: 1000;
}

.painting-slider__img {
  margin: 0 auto;
}
