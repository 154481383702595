.upload {
  width: 100%;
  min-height: 100vh;
  padding-top: 10rem;
  padding-bottom: 5rem;
  background-image: linear-gradient(
    to bottom right,
    rgba($color-nora-primary-light-3, 1),
    rgba($color-elaine-primary-light-1, 1)
  );

  &__container {
    width: 80rem;
    margin: 0 auto;
    @media only screen and (max-width: $bp-sm) {
      width: 80%;
    }
  }

  &__language-toggle {
    text-align: end;
    margin-bottom: 2rem;
  }

  &__heading {
    font-size: 3rem;
    text-transform: uppercase;
    background-image: linear-gradient(
      to right bottom,
      $color-nora-primary-light-1,
      $color-nora-primary-light-2
    );
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 600;
    grid-column: 1 / -1;
    justify-self: center;
  }

  &__creator {
    display: flex;
    grid-column: 1 / -1;
    font-size: 2rem;
    font-weight: 600;
    background-image: linear-gradient(
      to right bottom,
      $color-elaine-primary,
      $color-nora-primary
    );
    -webkit-background-clip: text;
    color: transparent;
  }

  &__btn,
  &__image {
    grid-column: 1 / -1;
    justify-self: center;
  }

  @media only screen and (max-width: $bp-sm) {
    &__created_date,
    &__category,
    &__content,
    &__name,
    &__key_word_1,
    &__key_word_2,
    &__key_word_3 {
      grid-column: 1 /-1;
    }
  }

  &__bottom__btns {
    width: 80rem;
    margin: 0 auto;
    text-align: end;
    margin-top: 3rem;
    padding-right: 1rem;
    @media only screen and (max-width: $bp-sm) {
      width: 60%;
    }
  }
}
