.image__upload {
  &__container {
    display: grid;
    justify-content: center;
    justify-items: center;
  }

  &__preview {
    width: 100%;
    margin-bottom: 1rem;
    &__icon {
      width: 50%;
    }
    img {
      width: 100%;
    }
  }
}
