.painting {
  &-searching-part {
    transition: all 0.1s linear;
    position: sticky;
    top: 0;
  }

  &__searchingbar {
    z-index: 200;
    max-width: 1600px;
    margin: 0 auto;
    width: 100%;
    padding: 0 8rem;
    display: grid;
    grid-template-columns: 1fr min-content;
    justify-content: space-around;
    column-gap: 2rem;
    margin-bottom: 4rem;
    @media only screen and (max-width: $bp-sm) {
      padding: 0 2rem;
      column-gap: 1rem;
    }
  }

  &__btn__elaine {
    background-color: $color-elaine-primary-dark;
    color: $color-elaine-primary-light-1;
    background-image: none;
  }

  &__searchinput,
  &__searchinput:focus {
    width: 100%;
    line-height: 5rem;
    background-color: $color-text-light-2;
    border: none;
    border-radius: 5px;
    font-size: 2.2rem;
    padding-left: 4rem;
    font-family: $font-text;
    outline: none;
    @media only screen and (max-width: $bp-sm) {
      font-size: 1.6rem;
      padding-left: 2rem;
    }

    &::-webkit-input-placeholder {
      color: $color-text-placeholder;
    }
  }

  &__categorys {
    max-width: 1600px;
    margin: 0 auto;

    // background-color: #fff;
    display: grid;
    grid-template-columns: repeat(6, max-content);
    padding: 0 10rem;
    justify-content: space-between;
    justify-items: center;
    margin-bottom: 6rem;
    @media only screen and (max-width: $bp-sm) {
      grid-template-columns: repeat(3, max-content);
      row-gap: 1rem;
      padding: 0 2rem;
    }
  }

  &__category {
    text-align: center;
    background-color: $color-elaine-primary-dark;
    color: $color-elaine-primary-light-1;
    font-family: $font-text;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.5rem 3rem;
    border-radius: 2px;
    width: 100%;
    box-shadow: $shadow-card;
    transition: all 0.2s linear;
    cursor: pointer;

    &__nora {
      background-color: $color-nora-primary-light-1;
      color: $color-nora-primary;
    }
    @media only screen and (max-width: $bp-lg) {
      padding: 0.5rem 1rem;
    }

    &:hover {
      color: $color-elaine-primary-dark;
      background-color: $color-elaine-primary-light-1;
    }
    &__nora:hover {
      background-color: $color-nora-primary-light-3;
      color: $color-milk-white;
    }

    &__selected {
      color: $color-elaine-primary-dark;
      background-color: $color-elaine-primary-light-1;
    }
    &__nora__selected {
      background-color: $color-nora-primary-light-3;
      color: $color-milk-white;
    }
  }

  &__keywords {
    max-width: 1600px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(8, max-content);
    padding: 0 12rem;
    justify-content: space-between;
    justify-items: center;
    text-transform: capitalize;
    font-weight: 700;
    color: $color-milk-white;
    text-shadow: $shadow-text-dark;
    margin-bottom: 6rem;
    transition: all 0.3s linear;
    @media only screen and (max-width: $bp-sm) {
      grid-template-columns: repeat(4, max-content);
      row-gap: 2rem;
      padding: 0 2rem;
    }
  }

  &__keyword {
    font-size: 1.6rem;
    transform: rotateZ(-10deg);
    cursor: pointer;
    transform-origin: center;
    &:hover {
      transform: rotateZ(-15deg) translateY(-2px);
    }
    @media only screen and (max-width: $bp-lg) {
      transform: rotateZ(-15deg);
    }

    &__selected {
      color: $color-elaine-primary-dark;
    }
    &__nora__selected {
      color: $color-nora-primary-light-3;
    }
  }

  &-search__topStick {
    // position: sticky;
    // top: 0;
    background-color: rgba(0, 0, 0, 0.734);
    padding-top: 2rem;
    padding-bottom: 1rem;
    backdrop-filter: blur(4px);
  }
  &-search__topStick__searchingbar {
    padding: 0 2rem;
    margin-bottom: 2rem;
  }
  &-search__topStick__Keywords {
    padding: 0 2rem;
    margin-bottom: 2rem;

    @media only screen and (max-width: $bp-lg) and (orientation:landscape){
      position: absolute;
      top: -10rem;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    @media only screen and (max-width: $bp-sm) {
      position: absolute;
      top: -10rem;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  &-search__topStick__category {
    margin-bottom: 2rem;
  }
}
