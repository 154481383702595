.button{
  font-size: 1.6rem;
  font-family: $font-text;
  color: $color-text-light-2;
  padding:.8rem 1.2rem;
  border-radius: 2px;
  border: none;
  background-image: linear-gradient(to right bottom, $color-nora-primary-light-1,$color-nora-primary-light-2);
  box-shadow: $shadow-card;
  cursor: pointer;
  transition: all .2s linear;

  &:hover{
    transform: translateY(-2px);
  }

  &__alarm{
    background-image: linear-gradient(to right bottom, rgba($color-alarm, 1),rgba($color-alarm, .8))
  }
}


.page-link{
  font-size: 1.8rem;
  font-weight: 700;
  text-shadow: $shadow-text-dark;
  display: inline-block;
  transition: all .2s linear;
  color: $color-milk-white;
  cursor: pointer;
  &:hover {
    transform: translateY(-1px);
    text-shadow: $shadow-text-light;
}
}