.user-info {

  &__name{
    grid-column: 1 / -1;
  }

  &__intro{
    grid-column: 1 / -1;
    width: 100%;
  }

  &__image,
  &__btn{
    grid-column: 1 / -1;
  }
  &__btn{
    justify-self: center;
  }
  @media only screen and ( max-width: $bp-sm) {
    &__username,
    &__password,
    &__creator,
    &__birth{
      grid-column: 1 /-1;
    }
  }
}

.signUp__container{
  position: relative;
  top: 0;
  left: 0;
  width: 80rem;
  margin: 0 auto;
  height: 150vh;
  transform: translateY(-100%);
  transition: all .3s linear;
  z-index: 100;

  @media only screen and ( max-width: $bp-sm) {
    width: 80%;
  }
  &__enter{
    transform: translateY(0);
  }
}