.painting-list {
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem;
  @media only screen and (max-width: $bp-sm) {
    padding: 0 2px;
  }

  &__img {
    flex-grow: 1;
    height: 25rem;
    object-fit: cover;
    padding: 1px;
    cursor: pointer;

    &:nth-last-child(5),
    &:nth-last-child(4),
    &:nth-last-child(3),
    &:nth-last-child(2),
    &:nth-last-child(1) {
      flex-grow: 0;
    }
    @media only screen and (max-width: $bp-lg) {
      &:nth-last-child(5),
      &:nth-last-child(4),
      &:nth-last-child(3) {
        flex-grow: 1;
      }
    }

    @media only screen and (max-width: $bp-sm) {
      // &:nth-last-child(2),
      height: 17rem;
      &:nth-last-child(2),
      &:nth-last-child(1) {
        flex-grow: 1;
      }
    }
  }
}

.image-detail {
  border: 1px solid white;
  width: 100vw;
  height: 100vh;
  &__container {
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100vw;
    height: 100vh;
  }

  @media only screen and (max-width: $bp-xxxl) {
    grid-template-columns: 1fr max-content;
    max-height: 100vh;
    justify-items: center;
  }
  @media only screen and (max-width: $bp-md) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    justify-content: center;
  }
  &__img {
    height: inherit;
    width: 100%;
    object-fit: contain;
    @media only screen and (max-width: $bp-xxxl) {
    }
    @media only screen and (max-width: $bp-md) {
      height: auto;
      align-items: end;
      max-height: 80vh;
    }
  }
  &__info {
    padding: 5rem 2rem;
    @media only screen and (max-width: $bp-xxxl) {
      align-self: center;
    }
    @media only screen and (max-width: $bp-md) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
      width: 100%;
      min-height: 15vh;
    }
  }
  &__title {
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  &__content {
    max-width: 15rem;
    font-weight: 300;
    margin-bottom: 4rem;
    border-bottom: 1px solid white;
    font-size: 1.4rem;
    text-transform: capitalize;
    display: block;
    @media only screen and (max-width: $bp-md) {
      width: 100%;
    }
  }
  &__name {
    grid-column: 1 / 3;
  }
}
